<template lang="pug">
  b-card.manager-machines-firmware-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-form.form-wc.form-machines-firmware-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-machine-firmware(
              :label="$t('activerecord.models.firmware.one')"
              label-for='machine-firmware_id'
              label-cols-md='2'
              label-align-md='right')
              multiselect#machine-firmware_id.shadow-sm.custom-select.is-required(
                name='firmware'
                v-model='machineFirmwareIdSelected'
                :options='form.selects.machineFirmwareIdOptions'
                :class='formValidationClass($v.form.data.attributes.firmware_id)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('machine.placeholders.firmware')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.machineFirmwareIdLoading'
                @search-change='searchFirmware'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}
              wc-forms-if#machine-firmware_id-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.firmware_id'
                :remote="formRemoteInvalidFeedback('firmware')"
                :validators='{ required: {} }')

      b-card-footer.border-0.bg-white
        b-button-group#form-firmware-buttons.wc-forms-buttons
          b-button.form-buttons-reset.text-white(
            type='reset'
            variant='secondary')
            i.fal.fa-fw.fa-redo
            |  {{ $t('shared.actions.reset') }}
          b-button.form-buttons-submit.text-white(
            type='submit'
            variant='primary'
            :disabled='formDisable || $v.form.data.$invalid')
            i.fal.fa-fw.fa-check-circle
            |  {{ $t('shared.actions.save') }}
          b-button.form-buttons-flash.text-white(
            v-if="formShow && $getDeep(form.data.relationships.firmware, 'data.id') && !$v.form.data.$dirty && form.data.attributes.policies.firmware === true"
            type='button'
            variant='info'
            :disabled='formDisable || $v.form.data.$invalid'
            v-b-modal.machine-show-firmware-update)
            i.fal.fa-fw.fa-usb-drive
            |  {{ $t('shared.actions.update') }}

      b-modal#machine-show-firmware-update.machine-show-firmware-modal(
        v-if='formShow'
        ref='machine-show-firmware-update'
        footer-class='justify-content-center'
        @ok='onFirmwareUpdate'
        ok-only
        centered)
        template(#modal-footer='{ ok }')
          b-button.text-white(
            variant='primary'
            :disabled='!isOnline || modal.start'
            @click='ok()')
            i.fal.fa-fw.fa-play
            |  {{ $t('shared.actions.start') }}
        .d-flex.flex-column.justify-content-center.align-self-center
          .d-flex.flex-column.align-items-center
            h5
              | {{ $t('firmware.titles.update') }}
          .d-flex.flex-column.align-items-center
            b-badge.ml-2(
              v-if='isOnline'
              variant='secondary'
              pill)
              | {{ $t('machine.connected') }}
            b-badge.ml-2(
              v-else
              variant='primary'
              pill)
              | {{ $t('machine.disconnected') }}

        .d-flex.justify-content-between.align-self-center.mx-3.mt-5.mb-3
          .d-flex.flex-column.align-items-center
            i.fal.fa-fw.fa-2x.fa-rocket-launch(:class='modalStepInit')
            .mt-2
              | {{ $t('firmware.status.init') }}
          .d-flex.flex-column.flex-grow-1.justify-content-center.align-items-center.mx-2
            hr.wc-dotted-line.m-0.w-100
          .d-flex.flex-column.align-items-center
            i.fal.fa-fw.fa-2x.fa-download(:class='modalStepDownload')
            .mt-2
              | {{ $t('firmware.status.download') }}
          .d-flex.flex-column.flex-grow-1.justify-content-center.align-items-center.mx-2
            hr.wc-dotted-line.m-0.w-100
          .d-flex.flex-column.align-items-center
            i.fal.fa-fw.fa-2x.fa-shield-check(:class='modalStepChecksum')
            .mt-2
              | {{ $t('firmware.status.checksum') }}
          .d-flex.flex-column.flex-grow-1.justify-content-center.align-items-center.mx-2
            hr.wc-dotted-line.m-0.w-100
          .d-flex.flex-column.align-items-center
            i.fal.fa-fw.fa-2x.fa-power-off(:class='modalStepReboot')
            .mt-2
              | {{ $t('firmware.status.reboot') }}

        .d-flex.flex-column.mt-5
          wc-forms-alerts(v-if='modal.error' :message='modalErrorMessage')
          wc-loaders-cube.align-self-center(v-else-if='this.modal.start')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/machines/shared/tabs'
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'
import apiFirmware from '@services/api/manager/machines/firmware'
import { Form, mapSelects } from '@common/form'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'manager-machines-firmware-form',
  mixins: [Tabs, Form],
  components: {
    WcLoadersCube,
  },
  computed: {
    ...mapSelects([
      {
        name: 'machineFirmwareId',
        default: 'relationships.firmware.data.id',
        attribute: 'attributes.firmware_id',
        included: { type: 'firmware', key: 'id', value: 'attributes.version' },
      },
    ]),
    isOnline() {
      return !!this.form.data.attributes.online
    },
    modalStepInit() {
      return this.$getDeep(this.modal, 'error.step') === 'init'
        ? 'text-danger'
        : this.modal.start && !this.modal.step
        ? 'text-warning'
        : this.modal.step
        ? 'text-success'
        : 'text-body'
    },
    modalStepDownload() {
      return this.$getDeep(this.modal, 'error.step') === 'download'
        ? 'text-danger'
        : this.modal.start && this.modal.step === 'init' && !this.modal.error
        ? 'text-warning'
        : this.modal.step && ['download', 'checksum', 'reboot'].includes(this.modal.step)
        ? 'text-success'
        : 'text-body'
    },
    modalStepChecksum() {
      return this.$getDeep(this.modal, 'error.step') === 'checksum'
        ? 'text-danger'
        : this.modal.start && this.modal.step === 'download' && !this.modal.error
        ? 'text-warning'
        : this.modal.step && ['checksum', 'reboot'].includes(this.modal.step)
        ? 'text-success'
        : 'text-body'
    },
    modalStepReboot() {
      return this.$getDeep(this.modal, 'error.step') === 'reboot'
        ? 'text-danger'
        : this.modal.start && this.modal.step === 'checksum' && !this.modal.error
        ? 'text-warning'
        : this.modal.step && this.modal.step === 'reboot'
        ? 'text-success'
        : 'text-body'
    },
    modalErrorMessage() {
      return {
        id: '',
        title: this.$t(`errors.resource.${this.modal.error.status}.title`),
        detail: this.$t(`errors.resource.${this.modal.error.status}.detail`),
      }
    },
    apiParams() {
      return {
        get: [{ machine_id: this.machine_id }, {}],
        update: [
          { machine_id: this.machine_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        machineFirmwareIdSearch: [
          { machine_id: this.machine_id },
          { term: this.form.selects.machineFirmwareIdTerm },
        ],
      }
    },
  },
  methods: {
    searchFirmware(term, id) {
      this.formMultiselectSearch(term, id, 'search', 'version')
    },
    channelSubscribe() {
      if (!this.$cable._channels.subscriptions['Api::V1::Machine::RpcChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::Machine::RpcChannel',
          id: this.machine_id,
        })
      }
      if (!this.$cable._channels.subscriptions['Api::V1::Machine::AttributesChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::Machine::AttributesChannel',
          id: this.machine_id,
        })
      }
    },
    onFirmwareUpdate(modal) {
      modal.preventDefault()
      this.cableRpcCleanup()
      this.modal.start = true
      this.modal.request_id = new Date().getTime()
      this.$cable.perform({
        channel: 'Api::V1::Machine::RpcChannel',
        action: 'firmware_update',
        data: {
          request_id: this.modal.request_id,
        },
      })
    },
    cableRpcDispatch() {
      /* Return if request_id don't match with this request. */
      if (
        this.modal.request_id !==
        (this.$getDeep(this.cable.rpc, 'result.request_id') ||
          this.$getDeep(this.cable.rpc, 'error.request_id'))
      )
        return

      /* Handle step response.*/
      this.modal.error = null
      if (this.cable.rpc.error) {
        this.modal.error = this.cable.rpc.error
        this.modal.start = ['locked', 'bad_request'].includes(this.modal.error.status)
      }
      this.modal.step =
        this.$getDeep(this.cable.rpc, 'result.step') || this.$getDeep(this.cable.rpc, 'error.step')
    },
    cableRpcCleanup() {
      this.modal.request_id = null
      this.modal.start = false
      this.modal.step = null
      this.modal.error = null
    },
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('firmware-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            firmware_id: {
              required,
              remote: () => this.formRemoteValid('firmware'),
            },
          },
        },
      },
    }
  },
  channels: {
    'Api::V1::Machine::RpcChannel': {
      connected() {
        this.cable.rpcConnected = true
      },
      rejected() {
        this.cable.rpcConnected = false
      },
      received(response) {
        this.cable.rpc = response || {}
        this.cableRpcDispatch()
      },
      disconnected() {
        this.cable.rpcConnected = false
      },
    },
    'Api::V1::Machine::AttributesChannel': {
      connected() {
        this.cable.attributesConnected = true
      },
      rejected() {
        this.cable.attributesConnected = false
      },
      received(response) {
        this.cable.attributes = response || {}
        if (Object.prototype.hasOwnProperty.call(this.cable.attributes, 'online')) {
          this.form.data.attributes.online = this.cable.attributes.online
          if (
            !this.isOnline &&
            this.$getDeep(this.modal, 'error.step') !== 'reboot' &&
            this.modal.step === 'reboot'
          ) {
            this.$refs['machine-show-firmware-update'].hide()
            this.cableRpcCleanup()
          }
        }
      },
      disconnected() {
        this.cable.attributesConnected = false
      },
    },
  },
  data() {
    return {
      apiBase: apiFirmware,
      apiModel: 'machine',
      machine_id: this.$route.params.machine_id,
      asyncDataSelects: ['machineFirmwareId'],
      modal: {
        request_id: null,
        start: false,
        step: null,
        error: null,
      },
      cable: {
        rpcConnected: false,
        rpc: {},
        attributesConnected: false,
        attributes: {},
      },
    }
  },
  mounted() {
    this.channelSubscribe()
  },
}
</script>
